const ArrivalCertKeys = {
  TITLE: "title",
  ID: "id",
  CCN: "ccn",
  ARRIVAL_DATE_TIME: "arrivalDateTime",
  ARRIVAL_TIME_ZONE: "arrivalTimeZone",
  DESTINATION_OFFICE_CODE: "destinationOfficeCode",
  WAREHOUSE_CODE: "warehouseCode",
  MESSAGE_FUNCTION: "messageFunction",
  BUSINESS_NUMBER: "businessNumber",
  STATUS: "status",
  REMARKS: "remarks",
  MODE_OF_SUBMISSION: "modeOfSubmission",
  PARTY_ID: "partyId",
  SUBMITTED_BY: "submittedBy",
  SUBMITTED_DATE: "submittedDate",
  CREATED_BY: "createdBy",
  CREATED_DATE: "createdDate",
  UPDATED_BY: "updatedBy",
  UPDATED_DATE: "updatedDate",
  VERSION: "version",
  RESPONSE_TITLE:"responseTitle",
  BUSINESS_NUM_FLAG:"businessNumFlag",
  SAVE_BUTTON: "saveButton",
  SUBMIT_BUTTON: "submitButton",
  UPDATE_BUTTON: "updateButton",
  CLONE_BUTTON: "cloneButton",
  CLONE_MULTIPLE_BUTTON: "cloneMultipleRecords",
  DELETE_BUTTON: "deleteButton",
  DELETE_MULTIPLE_RECORDS: "deleteMultiple",
  SUBMIT_MULTIPLE_RECORDS: "submitMultiple",
  ERROR: "error",
  SUBMIT_AS_MODIFY:"submitAsModify",
  Validations:{
    INVALID_ARRIVAL_DATE_TIME_FORMAT:"invalidDateTimeFormat",
    MAX_LENGTH_25:"maxLength25",
    MIN_LENGTH_5:"minLength5",
    ALPHA_NUMERIC_REGX:"alphaNumericReqx"
  }
}

export default ArrivalCertKeys
